import React, { useCallback, useEffect, useState } from "react";
import { axiosInstance } from "../../services/AxiosInstance";
import { useParams } from "react-router-dom";
import PlayerIndex from "./Player";
import { Button, Modal } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards } from "swiper";
import "swiper/css";
import "swiper/css/effect-cards";
import { toast } from "react-toastify";

const JudgesSong = () => {
  const { id } = useParams();
  const { event } = useParams();
  const [loader, setLoader] = useState(false);
  const [playingIndex, setPlayingIndex] = useState(null);
  const [data, setData] = useState([]);
  const [selected, setSelected] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [show, setShow] = useState(false);
  const [showPublish, setShowPublish] = useState(false);
  const onShow = () => setShow(true);
  const onHide = () => setShow(false);
  const onshowPublish = () => setShowPublish(true);
  const onHidePublish = () => setShowPublish(false);
  const itemsPerPage = 6;

  const AssignSong = useCallback(() => {
    setLoader(true);
    axiosInstance
      .get(`judge/events/${id}/songs`)
      .then(function (response) {
        console.log(response, "Getting Companies");
        if (response?.data.status === true) {
          setData(response?.data.data);
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
      });
  }, [id]);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handlePreviousClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedData = data.slice(startIndex, endIndex);
  const selectSong = (id) => {
    setLoader(true);
    try {
      axiosInstance
        .post(`judge/songs/mark_selected/${id}`)
        .then((response) => {
          console.log(response);
          if (response.data.status === true) {
            AssignSong();
            selectedSong();
            toast.success(response.data.response);
            setLoader(false);
          } else {
            setLoader(false);
            toast.error(response.data.error);
          }
        })
        .catch((error) => {
          setLoader(false);
          toast.success(error.response.data.erro);
        });
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };
  const unselectSong = (id) => {
    setLoader(true);
    try {
      axiosInstance
        .delete(`judge/songs/unmark_selected/${id}`)
        .then((response) => {
          console.log(response);
          if (response.data.status === true) {
            selectedSong();
            AssignSong();
            onHide();
            toast.success(response.data.response);
            setLoader(false);
          } else {
            setLoader(false);
            toast.error(response.data.error);
          }
        })
        .catch((error) => {
          setLoader(false);
          toast.success(error.response.data.erro);
        });
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };
  const selectedSong = useCallback(() => {
    setLoader(true);
    try {
      axiosInstance
        .get(`judge/events/${id}/songs/selected`)
        .then((response) => {
          console.log(response, "Selected");
          if (response.data.status === true) {
            setSelected(response?.data?.data);
            toast.success(response.data.response);
            setLoader(false);
          } else {
            setLoader(false);
            toast.error(response.data.error);
          }
        })
        .catch((error) => {
          setLoader(false);
          toast.success(error.response.data.erro);
        });
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  }, [id]);
  const Publish = () => {
    setLoader(true);
    try {
      axiosInstance
        .post(`judge/events/${id}/songs/selected/publish`)
        .then((response) => {
          console.log(response);
          if (response.data.status === true) {
            AssignSong();
            selectedSong();
            onHidePublish();
            toast.success(response.data.response);
            setLoader(false);
          } else {
            setLoader(false);
            toast.error(response.data.error);
          }
        })
        .catch((error) => {
          setLoader(false);
          toast.success(error.response.data.erro);
        });
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };
  useEffect(() => {
    AssignSong();
    selectedSong();
  }, [AssignSong, selectedSong]);

  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <div className="container">
        <div className="row">
          <div className="float-right">
            <div className="btn btn-primary float-end mb-3" onClick={onShow}>
              View Selected Songs
            </div>
          </div>
        </div>
        <div className="row">
          {displayedData.map((e, index) => {
            console.log(
              selected.filter((s) => s.judge_song.song_id === e.pivot.song_id),
              "Selected songs id"
            );
            return (
              <div className="col-md-4 col-sm-6" key={e.id}>
                <div className="position-relative mb-3">
                  <PlayerIndex
                    img={e.thumbnail}
                    name={e.title}
                    genre={e.genre.name}
                    event={event}
                    audio={e.song}
                    PlayingId={index}
                    isPlaying={index === playingIndex}
                    updatePlayingIndex={(i) =>
                      setPlayingIndex(i === playingIndex ? null : i)
                    }
                  />
                  {selected.find((s) => s.is_published === 1) ? null : (
                    <div
                      className="position-absolute bg-transparent rounded shadow-lg"
                      style={{ width: "15%", right: 26, top: 39 }}
                    >
                      <div className="text-center pt-3 pb-2">
                        <i
                          className={`fa fa-heart fs-3 ${
                            selected.find(
                              (s) => s.judge_song.song_id === e.pivot.song_id
                            )
                              ? "text-danger"
                              : "text-white"
                          }`}
                          onClick={() => {
                            selectSong(e.pivot.id);
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className="d-flex justify-content-between">
          <span>
            Page{" "}
            <strong>
              {currentPage} of {totalPages}
            </strong>
          </span>
          <span className="table-index">
            Go to page:{" "}
            <input
              type="number"
              className="ml-2"
              defaultValue={currentPage}
              onChange={(e) => {
                const page = parseInt(e.target.value);
                if (page >= 1 && page <= totalPages) {
                  setCurrentPage(page);
                }
              }}
            />
          </span>
        </div>
        <div className="text-center">
          <div className="filter-pagination  mt-3">
            <button
              className="previous-button"
              onClick={handlePreviousClick}
              disabled={currentPage === 1}
            >
              {"<<"}
            </button>
            <button
              className="previous-button"
              onClick={handlePreviousClick}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <button
              className="next-button"
              onClick={handleNextClick}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
            <button
              className="next-button"
              onClick={handleNextClick}
              disabled={currentPage === totalPages}
            >
              {">>"}
            </button>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={onHide} size="md">
        <Modal.Header closeButton>Selected Song</Modal.Header>
        <Modal.Body>
          <div className="container">
            <>
              <Swiper
                effect={"cards"}
                grabCursor={true}
                modules={[EffectCards]}
                className="mySwiper"
              >
                {selected &&
                  selected.reverse().map((e, index) => {
                    return (
                      <SwiperSlide>
                        <div className="position-relative">
                          <PlayerIndex
                            img={e.judge_song.song.thumbnail}
                            name={e.judge_song.song.title}
                            genre={
                              e.judge_song.song.genre_id === 1
                                ? "Rock"
                                : e.judge_song.song.genre_id === 2
                                ? "Pop"
                                : "Rock"
                            }
                            event={event}
                            audio={e.judge_song.song.song}
                            PlayingId={index}
                            isPlaying={index === playingIndex}
                            updatePlayingIndex={(i) =>
                              setPlayingIndex(i === playingIndex ? null : i)
                            }
                          />
                          {e.is_published === 1 ? null : (
                            <div
                              className="position-absolute bg-transparent rounded shadow-lg"
                              style={{ width: "15%", right: 26, top: 39 }}
                            >
                              <div className="text-center pt-3 pb-2">
                                <i
                                  className={`fa fa-trash fs-3 text-danger`}
                                  onClick={() => {
                                    unselectSong(e.id);
                                  }}
                                />
                              </div>
                            </div>
                          )}
                          <div
                            className="position-absolute "
                            style={{ width: "15%", left: 26, top: 39 }}
                          >
                            <div className="badge bg-success">
                              Rank {index + 1}
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {selected.find((s) => s.is_published === 1) ? null : (
            <div className="float-end btn btn-primary" onClick={onshowPublish}>
              Publish
            </div>
          )}
        </Modal.Footer>
      </Modal>
      <Modal show={showPublish} onHide={onHidePublish} size="md">
        <Modal.Header closeButton>
          <div className="text-center">
            <p className="text-center fs-3 fw-light">Disclaimer</p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <h3 className="text-center">
              Please be aware that once a song has been published, it cannot be
              edited or modified.!!!
            </h3>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-danger" onClick={Publish}>
            Publish it
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default JudgesSong;
