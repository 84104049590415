/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useRef, useState } from "react";
//** Import Image */
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../../services/AxiosInstance";
import { loginConfirmedAction } from "../../../../store/actions/AuthActions";
import PageTitle from "../../../layouts/PageTitle";
import bgwhite from "../../../../images/logo1.png";
const AppProfile = () => {
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [oldpassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [conpassword, setConPassword] = useState("");
  const [ischecked, setisChecked] = useState(false);
  const [image, setImage] = useState("");
  const [pdfBlobURL, setPdfBlobURL] = useState(null);
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    if (file) {
      const blobURL = URL.createObjectURL(file);
      setPdfBlobURL(blobURL);
    }
  };
  const changepassword = (e) => {
    const checked = e.target.checked;
    if (checked) {
      setisChecked(true);
    } else setisChecked(false);
  };
  const dispatch = useDispatch();
  const onChange = (setState) => (e) => {
    setState(e.target.value);
  };
  const UserDetails = useSelector((state) => state.auth.auth);

  const ref = useRef();

  const onClick = () => {
    ref.current.click();
  };

  const userData = () => {
    setLoader(true);
    axiosInstance
      .get(`current-user`)
      .then((response) => {
        console.log(response, "user response");
        const Data = response.data?.user;
        if (response.data?.status === true) {
          setData(Data);
          dispatch(loginConfirmedAction(response.data));
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((errors) => {
        setLoader(false);
        console.log(errors);
      });
  };

  useEffect(() => {
    userData();
  }, []);
  const viewPicture = () => {
    window.open(data?.image, "_blank");
  };
  const onUpdate = () => {
    setLoader(true);
    const urlencoded = new FormData();
    urlencoded.append("name", name ? name : data?.name);
    urlencoded.append("email", email ? email : data?.email);
    urlencoded.append("image", image);
    axiosInstance
      .post(`profile-update`, urlencoded)
      .then((response) => {
        if (response.data?.status === true) {
          toast.success(response.data?.message);
          userData();
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((errors) => {
        console.log(errors);
        setLoader(false);
      });
  };
  const onChangepassword = () => {
    setLoader(true);
    const urlencoded = new FormData();
    urlencoded.append("o_password", oldpassword);
    urlencoded.append("password", password);
    urlencoded.append("password_confirmation", conpassword);
    axiosInstance
      .post(`changepassword`, urlencoded)
      .then((response) => {
        if (response.data?.status === true) {
          toast.success(response.data?.message);
          // userData();
          setisChecked(false);
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((errors) => {
        console.log(errors);
        setLoader(false);
      });
  };
  return (
    <Fragment>
      {loader ? <div className="loader"></div> : null}
      <PageTitle activeMenu="Profile" motherMenu="App" />

      <div className="row">
        <div className="col-lg-12">
          <div className="profile card card-body px-3 pt-3 pb-0">
            <div className="profile-head">
              <div className="photo-content ">
                <div
                  className="cover-photo rounded"
                  style={{ backgroundImage: `url(${bgwhite})` }}
                ></div>
              </div>
              <div className="profile-info">
                <div className="profile-photo">
                  <Dropdown className="dropdown ms-auto">
                    <Dropdown.Toggle
                      //   variant="primary"
                      className="bg-transparent border-0"
                      data-toggle="dropdown"
                      aria-expanded="true"
                    >
                      <img
                        src={pdfBlobURL ? pdfBlobURL : data?.image}
                        className=" rounded-circle"
                        alt="profile"
                        height={77}
                        width={77}
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                      <Dropdown.Item
                        className="dropdown-item"
                        onClick={viewPicture}
                      >
                        <i className="fa fa-user-circle text-primary me-2" />
                        View Profile Picture
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="dropdown-item"
                        onClick={onClick}
                      >
                        <i className="fa fa-plus text-primary me-2" />
                        Add New Profile Picture
                      </Dropdown.Item>
                      {/* <Dropdown.Item
                        className="dropdown-item"
                        onClick={DeleteImg}
                      >
                        <i className="fa fa-trash text-danger me-2" />
                        Delete
                      </Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <input
                  type="file"
                  ref={ref}
                  className="d-none"
                  onChange={handleFileChange}
                />
                <div className="profile-details">
                  <div className="profile-name px-3 pt-2">
                    <h4 className="text-primary mb-0 text-capitalize">
                      {`${data?.name}`}
                      <span className="text-muted fs-5">(Driver)</span>
                    </h4>
                    <p>{UserDetails.professionalType}</p>
                  </div>
                  <div className="profile-email px-2 pt-2">
                    <h4 className="text-muted mb-0">{data?.email}</h4>
                    <p>Email</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <div className="profile-tab">
                <div className="custom-tab-1">
                  <div className="tab-content">
                    <div
                      id="profile-settings"
                      className={`tab-pane fade active show`}
                    >
                      <div className="pt-3">
                        <div className="settings-form">
                          <h4 className="text-primary">Account Setting</h4>
                          <form onSubmit={(e) => e.preventDefault()}>
                            <div className="row">
                              {!ischecked ? (
                                <>
                                  <div className="form-group mb-3 col-md-6">
                                    <label className="form-label">Email</label>
                                    <input
                                      defaultValue={data?.email}
                                      type="email"
                                      placeholder="Email"
                                      className="form-control"
                                      onChange={onChange(setEmail)}
                                    />
                                  </div>
                                  <div className="form-group mb-3 col-md-6">
                                    <label className="form-label">Name</label>
                                    <input
                                      defaultValue={data?.name}
                                      type="text"
                                      placeholder="1234 Main St"
                                      className="form-control"
                                      onChange={onChange(setName)}
                                    />
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="form-group mb-3 col-md-6">
                                    <label className="form-label">
                                      Old Password
                                    </label>
                                    <input
                                      type="password"
                                      placeholder="Password"
                                      className="form-control"
                                      onChange={onChange(setOldPassword)}
                                    />
                                  </div>
                                  <div className="form-group mb-3 col-md-6">
                                    <label className="form-label">
                                      New Password
                                    </label>
                                    <input
                                      type="password"
                                      placeholder="Password"
                                      className="form-control"
                                      onChange={onChange(setPassword)}
                                    />
                                  </div>
                                  <div className="form-group mb-3 col-md-6">
                                    <label className="form-label">
                                      New Password Confrim
                                    </label>
                                    <input
                                      type="password"
                                      placeholder="Password"
                                      className="form-control"
                                      onChange={onChange(setConPassword)}
                                    />
                                  </div>
                                </>
                              )}
                            </div>
                            <div className="row">
                              <div className="form-group mb-3 col-md-6">
                                <input
                                  type="checkbox"
                                  onChange={changepassword}
                                  checked={ischecked}
                                />
                                <label htmlFor="form-label">
                                  Change Password
                                </label>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="text-center">
                                  <button
                                    className="btn btn-primary w-50 mt-5"
                                    onClick={
                                      ischecked ? onChangepassword : onUpdate
                                    }
                                  >
                                    Update
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AppProfile;
