/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import "react-dropzone-uploader/dist/styles.css";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from "react-table";
import "./filtering.css";

import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../../services/AxiosInstance";
import { useSelector } from "react-redux";

export const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column;
  return (
    <div>
      <input
        className="form-control input-search"
        value={filterValue || ""}
        onChange={(e) => setFilter(e.target.value)}
      />
    </div>
  );
};

export const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <div>
      Search :{" "}
      <input
        type="search"
        className="ml-2 input-search form-control"
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
        style={{ width: "20%" }}
      />
    </div>
  );
};

export const DriverPDF = () => {
  const keys = ["id", "driver.name", "driver.email", "driver.role.name"];
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const onShow = () => setShow(true);
  const onHide = () => setShow(false);
  const [editshow, setEditShow] = useState(false);
  const onEditShow = () => setEditShow(true);
  const onEditHide = () => setEditShow(false);
  const [loader, setLoader] = useState(false);
  const [fieldValues, setFieldValues] = useState({});
  const [image, setImage] = useState(null);
  const [driverpdf, setDriverPdf] = useState(null);
  const driverData = useSelector((state) => state.driver.data);
  console.log(driverData);
  // // Define a function to handle the upload status
  // const getUploadParams = ({ meta }) => {
  //   return { url: "https://httpbin.org/post" }; // Replace with your upload endpoint
  // };

  // const handleProfileChange = ({ meta, file }, status) => {
  //   if (status === "done") {
  //     console.log("File uploaded:", file);
  //     setImage([...image, file]); // Add the uploaded file to the state
  //   }
  // };
  const COLUMNS = [
    ...keys.map((key) => ({
      Header:
        key.replace(/driver./g, "Driver ") === "driver role.name"
          ? "Role"
          : key.replace(/driver./g, "Driver "),
      Footer:
        key.replace(/driver./g, "Driver ") === "driver role.name"
          ? "Role"
          : key.replace(/driver./g, "Driver "),
      accessor: key,
      Filter: ColumnFilter,
    })),

    {
      Header: "Profile Picture",
      Footer: "Profile Picture",
      accessor: "image",
      Cell: ({ value }) => {
        return (
          <>
            <img
              src={value}
              alt=""
              width={60}
              style={{ borderRadius: "50%" }}
              height={60}
            />
          </>
        );
      },
    },

    {
      Header: "Action",
      Footer: "Action",
      Cell: ({ row }) => {
        const Deletepdf = (id) => {
          setLoader(true);
          try {
            axiosInstance
              .delete(`driverPdf/${id}`)
              .then((response) => {
                if (response.data.status === true) {
                  getdriverPdf();
                  toast.success(response.data.message);
                  setLoader(false);
                } else {
                  toast.error("SomeThing went Wrong");
                  setLoader(false);
                }
              })
              .catch((errors) => {
                toast.error("SomeThing went Wrong");
                setLoader(false);
              });
          } catch (error) {
            toast.error(error);
          }
        };
        return (
          <>
            <div className="d-flex ">
              <button
                onClick={() => {
                  getdriverpdfid(row.original.id);
                }}
                title="Edit"
                className={`btn btn-outline-success btn-sm rounded`}
              >
                <i className="fa fa-pen" />
              </button>
              <button
                onClick={() => {
                  Deletepdf(row.original.id);
                }}
                title="Edit"
                className={`btn btn-outline-danger btn-sm rounded`}
              >
                <i className="fa fa-trash" />
              </button>
            </div>
          </>
        );
      },
    },
  ];

  const [columns] = useState(COLUMNS);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;
  const { globalFilter, pageIndex } = state;

  const getdriverPdf = () => {
    setLoader(true);
    axiosInstance
      .get("driverPdf")
      .then(function (response) {
        console.log(response, "Getting Compaines");
        if (response?.data.status === true) {
          setData(response?.data.data);
          setLoader(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        console.log(error, "song Error");
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });
  };
  const getdriverpdfid = (id) => {
    setLoader(true);
    axiosInstance
      .get(`driverPdf/${id}`)
      .then(function (response) {
        if (response?.data.status === true) {
          setDriverPdf(response?.data?.driver_pdf);
          onEditShow();
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        console.log(error, "song Error");
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });
  };
  const addDriverpdf = () => {
    setLoader(true);
    var formdata = new FormData();
    formdata.append(`pdf`, image[0]);
    formdata.append(`driver_id`, fieldValues.driver_id);
    axiosInstance
      .post(`driverPdf`, formdata)
      .then((response) => {
        if (response.data.status) {
          getdriverPdf();
          onHide();
          toast.success(response.data.message);
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };

  useEffect(() => {
    getdriverPdf();
  }, []);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFieldValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <div className="card">
        <div>
          <div className="card-header">
            <h4 className="card-title float-left">Driver PDF</h4>
            <button className="btn btn-primary float-end" onClick={onShow}>
              Assign PDF To Driver
            </button>
          </div>
        </div>

        <div className="card-body">
          <div className="table-responsive">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            <table
              {...getTableProps()}
              id="table-to-export" // Add the id attribute
              className="table display"
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                        {/* {column.canFilter ? column.render("Filter") : null} */}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="">
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {" "}
                            {cell.render("Cell")}{" "}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between">
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
              {""}
            </span>
            <span className="table-index">
              Go to page :{" "}
              <input
                type="number"
                className="ml-2"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const pageNumber = e.target.value
                    ? Number(e.target.value) - 1
                    : 0;
                  gotoPage(pageNumber);
                }}
              />
            </span>
          </div>
          <div className="text-center">
            <div className="filter-pagination  mt-3">
              <button
                className=" previous-button"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>

              <button
                className="previous-button"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                Previous
              </button>
              <button
                className="next-button"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                Next
              </button>
              <button
                className=" next-button"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Add Modal */}
      <Modal show={show} onHide={onHide} size="lg">
        <Modal.Header closeButton>Add Borrower</Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className={`mb-3 col-md-6 col-sm-12`}>
                <h5 className="text-capitalize">Driver</h5>
                <select
                  name={"driver_id"}
                  className="form-control"
                  onChange={handleInputChange}
                >
                  <option selected hidden disabled>
                    Select Driver...
                  </option>
                  {driverData.map((e) => (
                    <option value={e.id}>{e.name}</option>
                  ))}
                </select>
              </div>
              <div className={`mb-3 col-md-6 col-sm-12`}>
                <h5 className="text-capitalize">PDF</h5>
                <input
                  type={"file"}
                  accept=".pdf"
                  className="form-control"
                  onChange={(e) => setImage(e.target.files)}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onHide}>
            Close
          </Button>
          <Button variant="success" onClick={addDriverpdf}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Add Modal Close */}
      {/* Update Modal Start */}
      <Modal show={editshow} onHide={onEditHide} size="lg">
        <Modal.Header closeButton>Driver Pdf</Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <iframe
                src={driverpdf?.pdf}
                frameBorder="3"
                width="100%" // Set the width to 100% for full-width display
                height="500px" // Set the height to your desired size, e.g., 500px
                title="PDF Viewer"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onEditHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Update Modal Close */}
    </>
  );
};
export default DriverPDF;
