/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import "react-dropzone-uploader/dist/styles.css";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from "react-table";
import "./filtering.css";

import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../../services/AxiosInstance";

export const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column;
  return (
    <div>
      <input
        className="form-control input-search"
        value={filterValue || ""}
        onChange={(e) => setFilter(e.target.value)}
      />
    </div>
  );
};

export const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <div>
      Search :{" "}
      <input
        type="search"
        className="ml-2 input-search form-control"
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
        style={{ width: "20%" }}
      />
    </div>
  );
};

export const Broker = () => {
  const keys = ["id", "name", "email", "role"];
  const [data, setData] = useState([]);
  const [eventkeys, setEventkeys] = useState([]);
  const [show, setShow] = useState(false);
  const onShow = () => setShow(true);
  const onHide = () => setShow(false);
  const [editshow, setEditShow] = useState(false);
  const onEditShow = () => setEditShow(true);
  const onEditHide = () => setEditShow(false);
  const [loader, setLoader] = useState(false);
  const [fieldValues, setFieldValues] = useState({});
  const [image, setImage] = useState(null);
  const [brokerId, setBrokerId] = useState(null);

  // // Define a function to handle the upload status
  // const getUploadParams = ({ meta }) => {
  //   return { url: "https://httpbin.org/post" }; // Replace with your upload endpoint
  // };

  // const handleProfileChange = ({ meta, file }, status) => {
  //   if (status === "done") {
  //     console.log("File uploaded:", file);
  //     setImage([...image, file]); // Add the uploaded file to the state
  //   }
  // };
  const COLUMNS = [
    ...keys.map((key) => ({
      Header: key.replace(/_/g, " "),
      Footer: key.replace(/_/g, " "),
      accessor: key === "role" ? "role.name" : key,
      Filter: ColumnFilter,
    })),

    {
      Header: "Profile Picture",
      Footer: "Profile Picture",
      accessor: "image",
      Cell: ({ value }) => {
        return (
          <>
            <img
              src={value}
              alt=""
              width={60}
              style={{ borderRadius: "50%" }}
              height={60}
            />
          </>
        );
      },
    },
    {
      Header: "status",
      Footer: "status",
      accessor: "is_active",
      Cell: ({ value, row }) => {
        const active = (id) => {
          setLoader(true);
          try {
            axiosInstance
              .get(`borrower/status/${id}`)
              .then((response) => {
                if (response.data.status === true) {
                  getBroker();
                  toast.success(response.data.message);
                  setLoader(false);
                } else {
                  toast.success(response.data.error);
                  setLoader(false);
                }
              })
              .catch((errors) => {
                toast.error("SomeThing went Wrong");
                setLoader(false);
              });
          } catch (error) {
            toast.error(error);
          }
        };
        return (
          <>
            <button
              class={`badge ${value === 0 ? "bg-danger" : "bg-success"}`}
              onClick={() => active(row.original.id)}
            >
              {value === 0 ? "De-active" : "Active"}{" "}
            </button>
          </>
        );
      },
    },
    {
      Header: "Action",
      Footer: "Action",
      Cell: ({ row }) => {
        const Deleteuser = (id) => {
          setLoader(true);
          try {
            axiosInstance
              .delete(`borrower/${id}`)
              .then((response) => {
                if (response.data.status === true) {
                  getBroker();
                  toast.success(response.data.message);
                  setLoader(false);
                } else {
                  toast.error("SomeThing went Wrong");
                  setLoader(false);
                }
              })
              .catch((errors) => {
                toast.error("SomeThing went Wrong");
                setLoader(false);
              });
          } catch (error) {
            toast.error(error);
          }
        };
        return (
          <>
            <div className="d-flex ">
              <button
                onClick={() => {
                  onEditShow();
                  getBrokerID(row.original.id);
                }}
                title="Edit"
                className={`btn btn-outline-success btn-sm rounded`}
              >
                <i className="fa fa-pen" />
              </button>
              <button
                onClick={() => {
                  Deleteuser(row.original.id);
                }}
                title="Edit"
                className={`btn btn-outline-danger btn-sm rounded`}
              >
                <i className="fa fa-trash" />
              </button>
            </div>
          </>
        );
      },
    },
  ];

  const [columns] = useState(COLUMNS);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;
  const { globalFilter, pageIndex } = state;

  const getBroker = () => {
    setLoader(true);
    axiosInstance
      .get("borrower")
      .then(function (response) {
        console.log(response, "Getting Compaines");
        if (response?.data.status === true) {
          setData(response?.data.data);
          const currentKeys = Object.keys(response?.data.data[0]);
          setEventkeys(currentKeys);
          setLoader(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        console.log(error, "song Error");
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });
  };
  const getBrokerID = (id) => {
    setLoader(true);
    axiosInstance
      .get(`borrower/${id}`)
      .then(function (response) {
        if (response?.data.status === true) {
          setBrokerId(response?.data?.borrower);
          setLoader(false);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        console.log(error, "song Error");
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      });
  };
  const addBroker = () => {
    setLoader(true);
    var formdata = new FormData();
    eventkeys
      .filter(
        (s) => s !== "id" && s !== "is_active" && s !== "image" && s !== "role"
      )
      .map((e) => formdata.append(e, fieldValues[e]));
    formdata.append(`image`, image[0]);
    formdata.append(`password`, fieldValues.password);
    axiosInstance
      .post(`borrower`, formdata)
      .then((response) => {
        if (response.data.status) {
          getBroker();
          onHide();
          toast.success(response.data.message);
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };
  const updateBroker = (id) => {
    setLoader(true);
    var urlencoded = new URLSearchParams();
    eventkeys
      .filter(
        (s) => s !== "id" && s !== "is_active" && s !== "image" && s !== "role"
      )
      .map((e) =>
        urlencoded.append(e, fieldValues[e] ? fieldValues[e] : brokerId?.[e])
      );
    axiosInstance
      .put(`borrower/${id}`, urlencoded)
      .then((response) => {
        if (response.data.status) {
          getBroker();
          onEditHide();
          toast.success("Profile Updated Successfully!");
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };

  useEffect(() => {
    getBroker();
  }, []);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFieldValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  return (
    <>
      {loader ? <div className="loader"></div> : null}
      <div className="card">
        <div>
          <div className="card-header">
            <h4 className="card-title float-left">Broker Mangement</h4>
            <button className="btn btn-primary float-end" onClick={onShow}>
              Add Broker
            </button>
          </div>
        </div>

        <div className="card-body">
          <div className="table-responsive">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            <table
              {...getTableProps()}
              id="table-to-export" // Add the id attribute
              className="table display"
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                        {/* {column.canFilter ? column.render("Filter") : null} */}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="">
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {" "}
                            {cell.render("Cell")}{" "}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-between">
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
              {""}
            </span>
            <span className="table-index">
              Go to page :{" "}
              <input
                type="number"
                className="ml-2"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const pageNumber = e.target.value
                    ? Number(e.target.value) - 1
                    : 0;
                  gotoPage(pageNumber);
                }}
              />
            </span>
          </div>
          <div className="text-center">
            <div className="filter-pagination  mt-3">
              <button
                className=" previous-button"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>

              <button
                className="previous-button"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                Previous
              </button>
              <button
                className="next-button"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                Next
              </button>
              <button
                className=" next-button"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Add Modal */}
      <Modal show={show} onHide={onHide} size="lg">
        <Modal.Header closeButton>Add Borrower</Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              {eventkeys
                .filter(
                  (s) =>
                    s !== "id" &&
                    s !== "is_active" &&
                    s !== "image" &&
                    s !== "role"
                )

                .map((e) => {
                  return (
                    <>
                      <div className={`mb-3 col-md-6 col-sm-12`}>
                        <h5 className="text-capitalize">
                          {e.replace(/_/g, " ")}
                        </h5>
                        <input
                          type={"text"}
                          name={e}
                          className="form-control"
                          onChange={handleInputChange}
                        />
                      </div>
                    </>
                  );
                })}
              <div className={`mb-3 col-md-6 col-sm-12`}>
                <h5 className="text-capitalize">Password</h5>
                <input
                  type={"password"}
                  name={"password"}
                  className="form-control"
                  onChange={handleInputChange}
                />
              </div>
              <div className={`mb-3 col-md-6 col-sm-12`}>
                <h5 className="text-capitalize">Profile Picture</h5>
                <input
                  type={"file"}
                  // name={"password"}
                  className="form-control"
                  onChange={(e) => setImage(e.target.files)}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onHide}>
            Close
          </Button>
          <Button variant="success" onClick={addBroker}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Add Modal Close */}
      {/* Update Modal Start */}
      <Modal show={editshow} onHide={onEditHide} size="lg">
        <Modal.Header closeButton>Broker Details</Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              {eventkeys
                .filter(
                  (s) =>
                    s !== "id" &&
                    s !== "is_active" &&
                    s !== "image" &&
                    s !== "role"
                )
                .map((e) => {
                  return (
                    <>
                      <div className={`mb-3 col-md-6 col-sm-12`}>
                        <h5 className="text-capitalize">
                          {e.replace(/_/g, " ")}
                        </h5>
                        <input
                          type={"text"}
                          defaultValue={brokerId?.[e]}
                          name={e}
                          className="form-control"
                          onChange={handleInputChange}
                        />
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={onEditHide}>
            Close
          </Button>
          <Button variant="success" onClick={() => updateBroker(brokerId.id)}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Update Modal Close */}
    </>
  );
};
export default Broker;
